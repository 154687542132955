import React from 'react';
import { useHistory } from 'react-router-dom';
import { PageSection } from 'adc-ui-components';
import NumberCircle from '../../../components/NumberCircle';
import { BEREAVEMENT_UPLOAD_DOCUMENTS } from '../../../helpers/routes';
import NavigationButtons from '../../../components/NavigationButtons';
import PageTitle from '../../../components/layout/PageTitle';

const BereavementEntry = () => {
  const history = useHistory();
  const instructions = [
    {
      step: 1,
      text:
        "Tell us whether you'd like to transfer account ownership or close the account.",
    },
    {
      step: 2,
      text: 'Provide the document(s) required for your chosen action.',
    },
    {
      step: 3,
      text: 'Provide information for both the account holder and yourself.',
    },
  ];

  return (
    <PageSection className="page-section--banner bereavement-banner">
      <PageTitle
        title="Steps to close or transfer an account when an account"
        titleLine2="holder has passed away"
        subtitle="We understand that there may be times when you need to take care of an account for your loved one or on behalf of someone else. We are here to support you during this difficult time."
        additionalDetail="Please note: Only bereavement-related requests will be reviewed."
        noFocus
      />
      <div className="card-group card-group--instructions mb24 text-align-left">
        {instructions.map(({ step, text }) => (
          <div className="card-group__item" key={step}>
            <div className="card card--has-media">
              <div className="card__media">
                <NumberCircle number={step} />
              </div>
              <div className="card__content">
                <p className="body1">{text}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <NavigationButtons
        labelForNext="Continue"
        labelForPrevious="Cancel"
        navExternalLink
        navLinkForBack="https://www.xfinity.com/support"
        handleSubmit={() => history.push(`${BEREAVEMENT_UPLOAD_DOCUMENTS}`)}
      />
    </PageSection>
  );
};

export default BereavementEntry;
