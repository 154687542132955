import React, { useLayoutEffect, useRef } from 'react';

const PageTitle = ({
  title,
  titleLine2 = undefined,
  titleClass = 'mb24',
  subtitle,
  subtitleClass = 'mb24 body2',
  additionalDetail,
  additionalDetailClass = 'mb24 body2',
  noFocus,
}) => {
  const titleRef = useRef(null);

  useLayoutEffect(() => {
    if (titleRef.current && !noFocus) {
      titleRef.current.focus();
    }
  }, [noFocus]);

  return (
    <>
      <h1 className={`${titleClass}`} ref={titleRef} tabIndex="-1">
        {title}
        {titleLine2 && (
          <>
            <br />
            {titleLine2}
          </>
        )}
      </h1>
      {subtitle && <p className={`${subtitleClass}`}>{subtitle}</p>}
      {additionalDetail && (
        <p className={`${additionalDetailClass}`}>{additionalDetail}</p>
      )}
    </>
  );
};

export default PageTitle;
